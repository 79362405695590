.headerPromoPage {
    .ant-checkbox-wrapper {
        .ant-checkbox {
            top: 0;
            .ant-checkbox-inner {
                width: 20px;
                height: 20px;
                border-color: #1B8ADC;
                border-radius: 3px;
            }
        }
    }
}