@import "../../../../../../../../src/shared/styles/colors.scss";
@import "../../../../../../../../src/shared/styles/breakpoints.scss";

.promoCard {

    .ant-card-body {
        padding: 0;

        .card-body {
            .section {
                &:first-child {
                    border-right: solid 1px #BBBBBB;
                    @media screen and (max-width: $sm-breakpoint) {
                        border-bottom: solid 1px #BBBBBB;
                        border-right: none;
                        padding-bottom: 15px;
                        margin-bottom: 15px;
                    }
                }
            }
        }
        .card-footer {
            background-color: #F1F4FF;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            .text-center {
                p {
                    color: #2D62ED;
                }
            }
        }
    }
}