@import "../../../../../../../../src/shared/styles/colors.scss";
@import "../../../../../../../../src/shared/styles/breakpoints.scss";


.modalCreatePromotion {
    .ant-steps {
        width: 50% !important;
        margin: 0 auto 1rem;

        @media screen and (max-width: 1024px) {
            width: 100% !important;
            margin-bottom: 1rem;
            .ant-steps-item {
                .ant-steps-item-tail {
                    margin-left: 16px;
                }
                .ant-steps-item-icon {
                    margin-left: 0;
                }
                .ant-steps-item-content {
                    text-align: left;
                }
            }
        }
    }

    .modal-body {
        margin-top: 1.875rem;

        .error-input {
            border-color: #cc0000;

            .ant-checkbox-inner {
                border-color: #cc0000;
            }
        }

        .ant-steps-item-icon {
            height: 26px !important;
        }

        .ant-steps-item-tail {
            padding-left: 0;
            padding-right: 0;
            top: 13px;
        }

        .ant-steps-item-finish {
            .ant-steps-item-tail {
                &::after {
                    background-color: #0BD834;
                }
            }
        }

        .ant-steps-item-content {
            position: absolute;
            top: -35px;
            right: 0;
            left: 0;

            .ant-steps-item-title {
                font-size: 12px;
                line-height: 23px;
            }
        }

        .FirstStepperScreen {
            margin-top: 3rem;
        }

        .form {
            .label {
                font-size: 14px;
                line-height: 23px;
            }

            .ant-checkbox {
                .ant-checkbox-inner {
                    width: 20px;
                    height: 20px;
                    border: solid 1px $colorSecondary_Base;
                }
            }

            .ant-checkbox-input {
                width: 20px;
                height: 20px;
                border-radius: 3px;
                border: solid 1px $colorSecondary_Base;
            }

            label {
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 0.5rem;

                .description-label {
                    font-size: 11px;
                    line-height: 18px;
                    font-style: italic;
                }
            }
        }

        .FourthStepperScreen,
        .ThirdStepperScreen {
            .border-right {
                border-right: solid 1px #4D4D4D42;

                @media screen and (max-width: $md-breakpoint) {
                    border-right: none;
                }
            }

            .select-container {
                @media screen and (max-width: $md-breakpoint) {
                    width: 100%;

                    .select {
                        width: 100% !important;
                    }
                }
            }
        }

        .ant-col {
            @media screen and (max-width: $md-breakpoint) {
                // padding: 0;
                width: 100% !important;
            }
        }

        .LastStepperScreen {
            font-size: 22px;
            line-height: 37px;

        }
    }
}