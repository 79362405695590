    $colorPrimary_Base: #338EF5;
    $colorSecondary_Base: #1B8ADC;
    $colorAccent_Base: #40adff;
    $colorSuccess_Base: #36c31d;
    $colorError_Base: #cc0000;
    $colorNeutral_Base: #7D7D7D;

    //primary colors
    $colorPrimary_1: lighten($colorPrimary_Base, 70);
    $colorPrimary_2: lighten($colorPrimary_Base, 55);
    $colorPrimary_3: lighten($colorPrimary_Base, 40);
    $colorPrimary_4: lighten($colorPrimary_Base, 25);
    $colorPrimary_5: lighten($colorPrimary_Base, 10);
    $colorPrimary_6: $colorPrimary_Base;
    $colorPrimary_7: darken($colorPrimary_Base, 5);
    $colorPrimary_8: darken($colorPrimary_Base, 20);
    $colorPrimary_9: darken($colorPrimary_Base, 30);
    $colorPrimary_10: darken($colorPrimary_Base, 40);

    //secondary colors 
    $colorSecondary_1: lighten($colorPrimary_Base, 70);
    $colorSecondary_2: lighten($colorPrimary_Base, 55);
    $colorSecondary_3: lighten($colorPrimary_Base, 38);
    $colorSecondary_4: lighten($colorPrimary_Base, 25);
    $colorSecondary_5: lighten($colorPrimary_Base, 10);
    $colorSecondary_6: $colorSecondary_Base;

    //success colors
    $colorSuccess_1: lighten($colorSuccess_Base, 70);
    $colorSuccess_2: lighten($colorSuccess_Base, 55);
    $colorSuccess_3: lighten($colorSuccess_Base, 40);
    $colorSuccess_4: lighten($colorSuccess_Base, 25);
    $colorSuccess_5: lighten($colorSuccess_Base, 10);
    $colorSuccess_6: $colorSuccess_Base;
    $colorSuccess_7: darken($colorSuccess_Base, 10);
    $colorSuccess_8: darken($colorSuccess_Base, 20);
    $colorSuccess_9: darken($colorSuccess_Base, 30);
    $colorSuccess_10: darken($colorSuccess_Base, 35);

    //error colors
    $colorError_1: lighten($colorError_Base, 70);
    $colorError_2: lighten($colorError_Base, 55);
    $colorError_3: lighten($colorError_Base, 40);
    $colorError_4: lighten($colorError_Base, 25);
    $colorError_5: lighten($colorError_Base, 10);
    $colorError_6: $colorError_Base;
    $colorError_7: darken($colorError_Base, 10);
    $colorError_8: darken($colorError_Base, 20);
    $colorError_9: darken($colorError_Base, 25);
    $colorError_10: darken($colorError_Base, 30);

    //neutral colors
    $colorNeutral_1: lighten($colorNeutral_Base, 70); //white
    $colorNeutral_2: lighten($colorNeutral_Base, 50);
    $colorNeutral_3: lighten($colorNeutral_Base, 47);
    $colorNeutral_4: lighten($colorNeutral_Base, 40);
    $colorNeutral_5: lighten($colorNeutral_Base, 10);
    $colorNeutral_6: $colorNeutral_Base;
    $colorNeutral_7: darken($colorNeutral_Base, 10);
    $colorNeutral_8: darken($colorNeutral_Base, 20);
    $colorNeutral_9: darken($colorNeutral_Base, 30);
    $colorNeutral_10: darken($colorNeutral_Base, 50); //black

    